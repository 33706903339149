<template>
  <div class="login">
    <div class="login-body">
      <!-- 图片 -->
      <!-- <div class="login-body-left"></div> -->
      <!-- 登录 -->
      <div class="login-body-right">
        <div class="login-box">
          <!-- 登录方式切换 -->
          <div class="login-tab">
            <!-- <div class="tab" :class="activeIdx === 0 ? 'active' : ''" @click="changeTab(0)">验证码登陆</div> -->
            <div class="tab" :class="activeIdx === 1 ? 'active' : ''" @click="changeTab(1)">超管登录</div>
          </div>
          <div class="login-form">
            <!-- 手机验证码登录 -->
            <div class="main-login" v-if="activeIdx === 0">
              <el-form status-icon ref="main" :model="main" :rules="mainRules" hide-required-asterisk>
                <el-form-item prop="no">
                  <el-input v-model.number="main.no" placeholder="请输入您的手机号" prefix-icon="el-icon-mobile" @blur="islogin"
                    @keyup.native="openFlag" @keydown.native="closeFlag"></el-input>
                </el-form-item>
                <el-form-item class="code" prop="code">
                  <el-input v-model="main.code" placeholder="短信验证码" prefix-icon="el-icon-message" @blur="islogin"
                    @keyup.native="openFlag" @keydown.native="closeFlag"></el-input>
                  <el-button type="primary" class="code-btn" :disabled="!!seconds || !main.no"
                    @click="onClickSendSmsButton">{{ codeBtn }}</el-button>
                </el-form-item>
              </el-form>
            </div>
            <!-- 子账号登录 -->
            <div class="child-login" v-if="activeIdx === 1">
              <el-form :rules="childRules" ref="child" :model="child" status-icon hide-required-asterisk>
                <el-form-item prop="name">
                  <el-input v-model.trim="child.name" placeholder="请输入账号名称" prefix-icon="el-icon-user"></el-input>
                </el-form-item>
                <el-form-item prop="psd">
                  <el-input v-model.trim="child.psd" placeholder="登录密码" prefix-icon="el-icon-lock" show-password></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div id="gt"></div>
            <div class="loginBtn">
              <el-button class="submit-btn" @click="onClickLoginButton" :disabled="isOK">{{ !isloading ? '登&nbsp;录' :
              '登&nbsp;录&nbsp;中' }}<span v-show="isloading"><i class="dot">•••</i></span></el-button>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="service"></div> -->
      <!-- <div class="service-text">在线客服</div> -->
    </div>
  </div>
</template>
<script>
import service from './api';
import MENU from './data';
import clone from '@/utils/clone';
import gt from "../../mixins/geetest";
var timer;
var flag = 0;
export default {
  data() {
    return {
      activeIdx: 1,
      main: { no: '', code: '' },
      child: { psd: '', name: '' },
      // 手机号登录校验
      mainRules: {
        no: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1(3|4|5|6|7|8|9)\d{9}$/, message: '请输入正确格式的手机号', trigger: 'blur' }
        ],
        code: [{ required: true, message: '请填写验证码', trigger: 'blur' }]
      },
      childRules: {
        name: [
          { required: true, message: '请输入账号名称', trigger: 'blur' },
          {
            pattern: /^[a-zA-z]\w{3,9}$/,
            message: '4-10位字符，可使用数字、字母、下划线，且以字母开头',
            trigger: 'blur'
          }
        ],
        psd: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            pattern: /^[a-zA-z0-9]\w{5,19}$/,
            message: '6-20位字符，可含数字、字母、下划线，不能以下划线开头',
            trigger: 'blur'
          }
        ]
      },
      captcha: false,
      seconds: 0,
      isOK: false,//是否能登陆
      isloading: false //登陆中
    };
  },
  computed: {
    codeBtn() {
      return this.seconds ? this.seconds + ' s' : '获取验证码';
    }
  },
  mounted() {
    this.initGt()
  },
  methods: {
    initGt() {
      return gt.register().then(captcha => {
        this.captcha = captcha;
        return this.captcha;
      })
      this.islogin();
    },
    changeTab(type) {
      this.activeIdx = type;
      this.initGt();
      type ? this.$refs['main'].resetFields() : this.$refs['child'].resetFields();
      this.isOK = true;
    },
    onClickSendSmsButton() {
      this.captcha.onReady(function () {
      }).onSuccess(() => {
        let result = this.captcha.getValidate();
        if (!result)
          return;
        // 登录
        this.getCode(result);
      });
      this.captcha.verify();
    },
    //   获取验证码
    getCode(extData = {}) {
      // 校验手机号
      let { no = '' } = this.main;
      let check = false;
      this.$refs['main'].validateField('no', err => (check = !err));
      if (!check || this.seconds > 0) return;
      clearTimeout(this.timer);
      return service.getCode({ phone: no, ...extData }).then(res => {
        let now = new Date().getTime() + 60 * 1000;
        this.till = now;
        this.countDown();
      });
    },
    // 倒计时
    countDown() {
      // 当前秒数取整
      let seconds = ~~((this.till - new Date().getTime()) / 1000);
      if (seconds > 0) {
        this.seconds = seconds;
        this.timer = setTimeout(() => this.countDown(), 1000);
      } else {
        this.seconds = 0;
      }
    },
    onClickLoginButton() {
      let check = false;
      this.$refs['child'].validate(res => (check = res));

      if (!check) return;

      if (this.activeIdx === 0) {
        this.login();
      } else {
        this.captcha.onReady(function () {
        }).onSuccess(() => {
          let result = this.captcha.getValidate();
          if (!result)
            return;
          // 登录
          this.login(result);
        });
        this.captcha.verify();
      }
    },
    login(extData = {}) {
      clearTimeout(this.loginTimer);
      if (!this.isloading) {
        this.isloading = true;
        this.isOK = true;
        if (this.activeIdx === 0) {
          this.loginTimer = setTimeout(() => this.loginBySms(extData), 500)
        } else {
          this.loginTimer = setTimeout(() => this.loginByPassword(extData), 500)
        }
      }
    },
    // 登录
    loginBySms(extData = {}) {
      let check = false;
      this.$refs['main'].validate(res => (check = res));
      if (!check) return;
      let { no = '', code = '' } = this.main;
      return service.loginByCode({ mobile: no, code }).then(res => {
        this.isloading = false;
        this.handleLogin(res)
      }).catch(() => {
        this.isloading = false;
        this.initGt()
      });
    },
    // 子账号登录
    loginByPassword(extData = {}) {
      let check = false;
      this.$refs['child'].validate(res => (check = res));
      if (!check) return;

      let { name = '', psd = '' } = this.child;
      return service.loginByPsd({ account: name, password: psd, ...extData }).then(res => {
        res.isChild = true;
        this.isloading = false;
        return this.handleLogin(res);
      }).catch(() => {
        this.isloading = false;
        this.initGt()
      });
    },
    async handleLogin(res) {
      localStorage.setItem('token', res.token || '');
      let menu = await this.getMenus(res.id);
      let navs = res.role_id === 1 ? [MENU.list[0]].concat(menu) : menu;
      res.navs = navs;
      localStorage.setItem('xinyou_admin_user', JSON.stringify(res));
      this.$router.push({ path: navs[0].path || navs[0].children[0].path });
    },
    getMenus(userId) {
      return service.menu({ userId }).then(res => {
        console.log('res', res)
        let { admin_jurisdiction = [] } = res;
        let id = res.id
        sessionStorage.setItem('id', id)
        // 拷贝菜单
        let copyMenu = clone.deep(MENU.list);
        let list = copyMenu.filter(item => {
          if (admin_jurisdiction.some(el => el.name === item.name)) {
            // 匹配到父菜单 过滤子菜单
            'children' in item &&
              (item.children = item.children.filter(child => admin_jurisdiction.some(el => el.name === child.name)));
            return item;
          }
        });
        return list;
      });
    },
    //是否能登陆
    islogin() {
      if (flag == 1) {
        let check = false;
        if (this.activeIdx === 0) {
          this.$refs["main"].validate((res) => (check = res));
        } else {
          this.$refs["child"].validate((res) => (check = res));
        };

        this.isOK = !check;
      }
      clearTimeout(timer);
      flag = 0;
    },
    openFlag() {
      let self = this;
      timer = setTimeout(function () {
        flag = 1;
        self.islogin();
      }, 500);
    },
    closeFlag() {
      clearTimeout(timer);// 取消定时器
      flag = 0;
    }
  },
  beforeDestroy() {
    clearTimeout(this.timer);
    clearTimeout(this.loginTimer);
  }
};
</script>
<style lang="scss" scoped>
.login {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #120f39;
  display: flex;

  &-body {
    width: 440px;
    margin: auto;
    padding: 60px 0;
    background-color: #fff;
    -webkit-box-shadow: 2px 2px 8px rgba(0, 0, 0, .2);
    box-shadow: 2px 2px 8px rgba(0, 0, 0, .2);
    border-radius: 10px;
    overflow: hidden;

    &-left {
      height: 100%;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: top left;
    }

    &-right {
      height: 100%;
      background-color: #fff;
      position: relative;

      .service {
        position: absolute;
        border-right: 170px solid #f56c6c;
        border-bottom: 170px solid transparent;
        top: -15px;
        right: -15px;
      }

      .service-text {
        position: absolute;
        //width: 170px;
        letter-spacing: 2px;
        font-size: 16px;
        top: 45px;
        right: 15px;
        color: #fff;
        transform: rotate(45deg);
      }

      .footer {
        font-size: 14px;
        margin-top: 10px;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  &-tab {
    width: 320px;
    margin: 0 auto;
    display: flex;

    .tab {
      margin-right: 30px;
      cursor: pointer;
      font-size: 24px;

      &.active {
        font-weight: 600;
      }
    }
  }

  &-form {
    width: 320px;
    margin: 0 auto;
    margin-top: 30px;

    .el-input {
      font-size: 16px;
    }
  }

  .code {
    .el-input {
      width: 200px;
      margin-right: 5px;
    }

    &-btn {
      width: 112px;
    }
  }

  .loginBtn {
    .submit-btn {
      width: 320px;
      margin-top: 12px;
      font-size: 18px;
      //letter-spacing: 10px;
      color: #ffffff;
      background-color: #f56c6c;
      border-color: #f56c6c;
    }

    .is-disabled {
      background-color: #e2e2e2;
      border-color: #e2e2e2;

      &:hover {
        background-color: #e2e2e2;
        border-color: #e2e2e2;
      }
    }

    span {
      padding: 0;
      display: inline-block;
      width: 1.5rem;

      .dot {
        display: inline-block;
        font-size: 14px;
        letter-spacing: 2px;
        //width: 2em;
        vertical-align: bottom;
        overflow: hidden;
        animation: dot 1s infinite step-start;
      }

      @keyframes dot {
        0% {
          width: 0;
          margin-right: 1.5em;
        }

        33% {
          width: .5em;
          margin-right: 1em;
        }

        66% {
          width: 1em;
          margin-right: .5em;
        }

        100% {
          width: 1.5em;
          margin-right: 0;
        }
      }
    }
  }

  .regist-box {
    padding-top: 50px;
  }

  .regist-form {
    width: 320px;
    margin: 0 auto;

    .title {
      font-size: 26px;
      font-weight: 500;
      text-align: left;
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 480px) {
  .login {
    padding: 0 8%;
  }
}

@media screen and (max-width: 480px) {
  .login-body {
    padding: 50px 30px 30px;
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .login-form {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .submit-btn {
    width: 100%;
  }
}

@media screen and (max-width: 480px) and (max-width: 480px) {
  .loginBtn .submit-btn.el-button {
    width: 100%;
    margin-bottom: 2rem;
  }
}
</style>
